import React from "react";

import clsx from "clsx";

import { Box } from "~components/Box";
import { DialogMockup } from "~components/PhoneMockup/DialogMockup/DialogMockup";
import { STEP_TYPES } from "~components/Stepper/types";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { MOCK_UI_DIALOGS } from "~animations/mock_ui/MockUiMap";

import {
  AnimationSliderItem,
  getAnimationComponent,
} from "./AnimationSliderItem";
import * as styles from "./styles.css";

import type { AnimationSliderProps } from "./AnimationSlider.types";

export function AnimationSlider({
  items,
  className: userClassName,
  animationComponentMap: map,
  current,
  position: itemPosition = "absolute",
  inset: itemInset = "0",
  ...rest
}: AnimationSliderProps) {
  const itemsWithNumberAndType = items.map((item, index) => {
    let { stepType } = item;

    // Default to screen if stepType is not defined and it's not a dialog
    if (!stepType) {
      stepType = getAnimationComponent(item.animation[0], MOCK_UI_DIALOGS)
        ? "dialog"
        : "screen";
    }

    return {
      ...item,
      stepNumber: index,
      stepType,
    };
  });
  const screenItems = itemsWithNumberAndType.filter((item) => {
    return item.stepType === STEP_TYPES.SCREEN;
  });
  const dialogItems = itemsWithNumberAndType.filter((item) => {
    return item.stepType === STEP_TYPES.DIALOG;
  });

  return (
    <Box
      className={clsx(userClassName, styles.animationSliderWrapper)}
      {...rest}
    >
      {screenItems.map((item) => {
        return (
          <AnimationSliderItem
            key={item.stepNumber}
            data-motion-group={item.stepNumber}
            position={itemPosition}
            inset={itemInset}
            item={item}
            map={map}
            current={current}
          />
        );
      })}
      <DialogMockup
        shouldOpen={dialogItems.some((item) => {
          return item.stepNumber === current;
        })}
        currentStep={current}
      >
        {dialogItems.map((item) => {
          return (
            <ShowChildren when={item.stepNumber === current}>
              <AnimationSliderItem
                key={item.stepNumber}
                item={item}
                map={map}
                current={current}
              />
            </ShowChildren>
          );
        })}
      </DialogMockup>
    </Box>
  );
}
