import React from "react";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import * as styles from "./styles.css";

import type {
  Animation,
  AnimationSliderItemProps,
} from "./AnimationSlider.types";
import type { ComponentMap } from "~types/global.types";

export function getAnimationComponent(
  animationBlokProps: Animation | undefined,
  animationComponentMap: ComponentMap
) {
  return (
    (animationBlokProps?.component &&
      animationComponentMap[animationBlokProps.component]) ||
    null
  );
}

export const getAnimationSliderItemID = (stepNumber: number | undefined) => {
  return `animation-slider-item-${stepNumber}`;
};

export function AnimationSliderItem({
  item,
  map,
  current,
  ...rest
}: AnimationSliderItemProps) {
  /** Get the animation component configured in the Storyblok */
  const { animation: animationBlokArray } = item;
  const [animationProps] = animationBlokArray || [];

  /** Get the corresponding react component */
  const AnimationComponent = getAnimationComponent(animationProps, map);
  if (!AnimationComponent) return null;

  return (
    <StoryblokEditable key={item._uid} {...animationProps}>
      <Box
        id={getAnimationSliderItemID(item.stepNumber)}
        data-testid={getAnimationSliderItemID(item.stepNumber)}
        key={item._uid}
        /** Tag this as group containing multiple elements to be animated */
        data-motion-group={item.stepNumber}
        className={styles.animationContainer({ stepType: item.stepType })}
        {...rest}
      >
        <AnimationComponent
          {...animationProps}
          isCurrent={current === item.stepNumber}
        />
      </Box>
    </StoryblokEditable>
  );
}
