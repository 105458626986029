import React from "react";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { Image } from "~components/Image";
import { PhoneMockup } from "~components/PhoneMockup";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { AnimLayerTravelBookingInfo } from "~animations/__layers__/mock_ui_components/AnimLayerTravelBookingInfo";
import { AnimLayerTravelHotelInfo } from "~animations/__layers__/mock_ui_components/AnimLayerTravelHotelInfo";
import { AnimLayerUiBottomCheckout } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomCheckout";
import { AnimLayerUiTopNav } from "~animations/__layers__/mock_ui_components/AnimLayerUiTopNav";

import {
  type FakeBrandLogo,
  type FakeBrands,
  THEME_CLASS_MAP,
} from "~types/fakeBrands.types";

import type { AnimLayerTravelBookingInfoProps } from "~animations/__layers__/mock_ui_components/AnimLayerTravelBookingInfo";
import type { AnimLayerTravelHotelInfoProps } from "~animations/__layers__/mock_ui_components/AnimLayerTravelHotelInfo";
import type { AnimLayerUiBottomCheckoutProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiBottomCheckout";
import type { AnimatedHeroIllustration } from "~animations/products_addons/types";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface AnimIndustryTravelProps
  extends StoryblokBlok,
    AnimatedHeroIllustration {
  animLayerTravelHotelInfo: Array<
    StoryblokBlok & AnimLayerTravelHotelInfoProps
  >;
  animLayerTravelBookingInfo: Array<
    StoryblokBlok & AnimLayerTravelBookingInfoProps
  >;
  animLayerUiBottomCheckout: Array<
    StoryblokBlok & AnimLayerUiBottomCheckoutProps
  >;
  image: StoryblokFieldMedia;
  title?: string;
  brandLogo?: FakeBrandLogo;
  fakeBrand?: FakeBrands;
  titleDescription?: string;
  description?: string;
}

export function AnimIndustryTravel({
  animLayerTravelHotelInfo: animLayerTravelHotelInfoBlokArray,
  animLayerTravelBookingInfo: animLayerTravelBookingInfoBlokArray,
  animLayerUiBottomCheckout: animLayerUiBottomCheckoutBlokArray,

  image,
  brandLogo,
  fakeBrand,
  titleDescription,
  description,
}: AnimIndustryTravelProps) {
  const [animLayerTravelHotelInfo] = animLayerTravelHotelInfoBlokArray || [];
  const [animLayerTravelBookingInfo] =
    animLayerTravelBookingInfoBlokArray || [];
  const [animLayerUiBottomCheckout] = animLayerUiBottomCheckoutBlokArray || [];
  const fakeBrandTheme = fakeBrand ? THEME_CLASS_MAP[fakeBrand] : "";

  return (
    <PhoneMockup
      aspectRatio="mobile"
      display="flex"
      gap="spacing1"
      flexDirection="column"
      className={fakeBrandTheme}
    >
      <ShowChildren when={brandLogo}>
        <AnimLayerUiTopNav brandLogo={brandLogo} padding="phoneSpacing0" />
      </ShowChildren>

      {image && (
        <Image
          marginX="spacing1"
          borderRadius="sm"
          image={image}
          border="default"
          minHeight="min-content"
        />
      )}

      {animLayerTravelHotelInfo && (
        <AnimLayerTravelHotelInfo
          marginX="spacing2"
          {...animLayerTravelHotelInfo}
        />
      )}

      {animLayerTravelBookingInfo && (
        <AnimLayerTravelBookingInfo
          marginX="spacing2"
          className={variantBackgroundColor.background_mockUiCard}
          {...animLayerTravelBookingInfo}
        />
      )}

      <ShowChildren when={!!description}>
        <Box marginX="spacing2">
          <Box
            textAppearance="phone_text_md"
            fontWeight="semibold"
            marginBottom="spacing1"
          >
            {titleDescription}
          </Box>
          <Box textAppearance="phone_text_sm">{description}</Box>
        </Box>
      </ShowChildren>

      {animLayerUiBottomCheckout && (
        <AnimLayerUiBottomCheckout {...animLayerUiBottomCheckout} />
      )}
    </PhoneMockup>
  );
}
